export default {
    pages: [{
            name: "Izvestaji",
            slug: "izvestaji",
            id: 1,
            description: "izvestaji",
        },
        {
            name: "Dokumenti",
            slug: "dokumenti",
            id: 2,
            description: "dokumenti",
        },
        {
            name: "Komitenti",
            slug: "komitenti",
            id: 3,
            description: "komitenti",
        },
    ],
    subjekti: [{
            name: "Kompex d.o.o.",
            id: 111111
        },
        {
            name: "Nestorovic i sinovi",
            id: 120000
        },
        {
            name: "Srboklima",
            id: 111331
        }
    ],
    korisnikImei: [{
            ime: "Mirko Travica",
            lozinka: "mirko",
            id: 11,
            subjekti: [111111, 111331]
        },
        {
            ime: "Drasko Mitic",
            lozinka: "drasko",
            id: 12,
            subjekti: [111111]
        },
        {
            ime: "Srbo Srbojevic",
            lozinka: "srbo",
            id: 13,
            subjekti: [120000]
        }
    ]
};