<template>
    <div
        :class="`${classID} ${
            textarea ? ' xbec__input__textarea' : ' xbec__input__html'
        } ${inline && 'inline'} ${
            background && `${background}-background`
        } ${additionalClass}`"
        :contenteditable="!readonly"
        @keydown="handleKeyDown($event)"
        @input="handleUpdate($event)"
        @focus="inFocus = true"
        @blur="inFocus = false"
        v-html="textValue"
        :key="forceReactivityKey"
    ></div>
</template>

<script>
export default {
    name: "HtmlInputComponent",
    props: {
        externalValue: {},
        inline: {
            type: Boolean,
            default: false,
        },
        textarea: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        background: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        additionalClass: {
            type: String,
            default: "",
        },
    },
    model: {
        prop: "externalValue",
        event: "update",
    },
    data() {
        return {
            id: this.generateRandomHex(16),
            inFocus: false,
            textValue: "",
            forceReactivityKey: this.generateRandomHex(24),
        };
    },
    computed: {
        classID: function () {
            return `js-input-${this.id}`;
        },
        internalValue: {
            get: function () {
                return [null, "", "&zwnj;"].includes(this.externalValue)
                    ? "&zwnj;"
                    : this.externalValue;
            },
            set: function (newValue) {
                this.$emit(
                    "update",
                    newValue == "&zwnj;"
                        ? ""
                        : this.inline
                        ? newValue
                              .replaceAll("<br>", "")
                              .replaceAll("&nbsp;", "")
                        : newValue.replaceAll("&nbsp;", "")
                );
            },
        },
    },
    watch: {
        internalValue: function (newValue) {
            if (!this.inFocus) {
                this.textValue = newValue;
                this.forceReactivityKey = this.generateRandomHex(24);
            }
        },
        inFocus: function (newValue) {
            if (newValue) this.addPasteFunctionality();
            else this.$emit("blur");
        },
    },
    methods: {
        handleKeyDown: function (event) {
            const disallowedKeys = ["Enter"];
            if (disallowedKeys.includes(event.key) && this.inline) {
                event.preventDefault();
            }
        },
        handleUpdate: function (event) {
            this.internalValue = event.target.innerHTML;
        },
        handlePaste: function (event) {
            event.preventDefault();
            // following line works on Google Chrome and Firefox, possibly on IE
            const text = (event.originalEvent || event).clipboardData.getData(
                "text/plain"
            );
            // execCommand is depracated, but works for now. Ideally, an alternative should be found.
            document.execCommand("insertHTML", false, text);
        },
        addPasteFunctionality: function () {
            const input = document.querySelector(`.${this.classID}`);
            input.removeEventListener("paste", this.handlePaste);
            input.addEventListener("paste", this.handlePaste);
        },
    },
    created: function () {
        this.textValue = this.externalValue;
    },
    mounted: function () {
        this.addPasteFunctionality();
    },
};
</script>

<style lang="scss" scoped>
.xbec__input__html {
    cursor: text;
    min-height: 32px;
    width: 100%;
    padding: 4px 8px;

    &.inline {
        height: 32px;
        white-space: nowrap;
        text-overflow: "";
        overflow: hidden;
        max-width: 100%;
    }

    &.white-background {
        background-color: var(--clr-white);
    }

    &.light-background {
        background-color: var(--clr-gray-100);
    }
}

.xbec__input__textarea {
    cursor: text;
    height: 124px;
    width: 100%;
    padding: 4px 8px;
    overflow-y: scroll;
}
</style>