<template>
    <div class="okvir-pretrage">
        <table class="tabela-pretrage pointer">
            <tr v-for="p in obradjenaPretraga" :key="p" @click="izbor(p)">
                <td class="red-pretrage">
                    <span>
                        {{ p }}
                    </span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: "PonudaPretrage",
    props: {
        pretraga: {
            type: Array,
        },
        unos: {
            type: String,
        },
        filter: {
            type: Boolean,
        },
    },
    computed: {
        obradjenaPretraga: function () {
            if (this.filter == true) {
                let op = [];
                for (let i = 0; i < this.pretraga.length; i++) {
                    if (
                        this.pretraga[i]
                            .toLowerCase()
                            .includes(this.unos.toLowerCase())
                    ) {
                        op.push(this.pretraga[i]);
                    }
                }
                return op;
            } else {
                return this.pretraga;
            }
        },
    },
    methods: {
        izbor: function ($$vrednost) {
            console.log($$vrednost);
            this.$emit("izbor", $$vrednost);
        },
    },
};
</script>

<style scoped>
.okvir-pretrage {
    position: absolute;
    z-index: 500;
    max-height: 300px;
    width: 90%;
    left: 50%;
    transform: translate(-50%);
    background: var(--clr-white);
    border-radius: 0 0 0.25rem 0.25rem;
    overflow-y: auto;
    box-shadow: 1px 3px 11px 0px rgba(0, 0, 0, 0.04);
}
.tabela-pretrage {
    width: 100%;
}
.red-pretrage {
    padding: 0.4rem 1rem;
    font-weight: 350;
    color: #495057;
}
td:hover {
    background: #f7f6f6;
}
td:active {
    background: #eeeded;
}
</style>