import Vue from "vue";
import CRUD from "@/crud.js";

// var crud = new CRUD();

var ac = {
    methods: {
        get: async function (module, id) {
            return new CRUD().read(module, id);
        }
    }
}

var apiCommander = Vue.extend({
    mixins: [ac]
});

export default apiCommander;