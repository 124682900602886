
<template>
    <span>
        <b-icon
            :id="naziv"
            style="cursor: pointer"
            icon="question-circle"
            aria-hidden="true"
            variant="secondary"
        ></b-icon>
        <b-tooltip :target="naziv" triggers="hover">
            {{ opis }}
        </b-tooltip>
    </span>
</template>

<script>
export default {
    props: {
        naziv: {
            type: String,
        },
        opis: {
            type: String,
        },
    },
};
</script>