
<template>
    <div v-if="modalVisibility" class="modal-container">
        <div class="standard-modal-backdrop"></div>
        <div class="standard-modal">
            <div v-if="type == 'subject'" class="preset-modal modal-subject">
                <div class="panel-subject-highlighted">
                    <div class="psh-avatar">
                        <b-avatar
                            class="psh-avatar-icon"
                            button
                            size="lg"
                        ></b-avatar>
                    </div>
                    <div class="psh-name">
                        <span>{{
                            highlightedSubject.ime ||
                            $store.state.subjekatIzabrani.ime
                        }}</span>
                    </div>
                    <div class="psh-info">
                        <div class="psh-info-1">
                            <p class="psh-small-info">MB</p>
                            <p class="psh-normal-info">
                                {{
                                    highlightedSubject.mb ||
                                    $store.state.subjekatIzabrani.mb
                                }}
                            </p>
                        </div>
                        <div class="psh-info-2">
                            <p class="psh-small-info">PIB</p>
                            <p class="psh-normal-info">
                                {{
                                    highlightedSubject.pib ||
                                    $store.state.subjekatIzabrani.pib
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="panel-subject-offer">
                    <div class="psh-list">
                        <div
                            v-for="subject in $store.state.korisnikSubjekti"
                            :key="subject.mb"
                            :class="
                                'psh-list-item' +
                                (subject.mb == (highlightedSubject || {}).mb
                                    ? ' psh-list-item-highlight'
                                    : '')
                            "
                            @click="highlightSubject(subject)"
                        >
                            {{ subject.ime }}
                            <b-icon
                                v-if="
                                    subject.mb ==
                                    $store.state.subjekatIzabrani.mb
                                "
                                scale="0.65"
                                icon="star-fill"
                                class="psh-list-active-item-symbol"
                            ></b-icon>
                        </div>
                    </div>
                    <div class="psh-buttons">
                        <div class="modal-button-regular" @click="hideModal()">
                            Odustani
                        </div>
                        <div
                            class="modal-button-blue"
                            @click="selectNewSubject()"
                        >
                            Potvrdi
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="type == 'response'" class="preset-modal modal-response">
                <div class="mrs-message">{{ response }}</div>
                <div
                    class="modal-button-regular mrs-button"
                    @click="hideModal()"
                >
                    U redu
                </div>
                <div v-if="responseHint != ''" class="mrs-hint">
                    <objasnjenje naziv="mrs-hint-icon" :opis="responseHint" />
                </div>
            </div>
            <div v-if="type == 'query'" class="preset-modal modal-query">
                <!-- <div class="mqr-title">Upit</div> -->
                <div class="mqr-content">
                    <b-form-textarea
                        id="mqr-textarea"
                        v-model="query"
                        placeholder="Rezultat upita"
                        rows="6"
                        max-rows="10"
                        readonly
                    ></b-form-textarea>
                </div>
                <div class="mqr-buttons">
                    <div class="modal-button-regular" @click="hideModal()">
                        Zatvori
                    </div>
                    <div
                        class="modal-button-regular"
                        @click="copyQueryToClipboard()"
                    >
                        Kopiraj
                    </div>
                </div>
            </div>
            <div v-if="type == 'action'" class="preset-modal modal-action">
                <div class="mac-close" @click="hideModal">
                    <b-icon icon="x"></b-icon>
                </div>
                <div class="mac-title">{{ actionTitle }}</div>
                <div class="mac-message">{{ actionMessage }}</div>
                <div class="mac-buttons">
                    <div
                        v-for="button in actionButtons"
                        :key="'mac-' + button.text"
                        :class="'modal-button-' + button.variant"
                        @click="$emit(button.text.replaceAll(' ', '-'))"
                    >
                        {{ button.text }}
                    </div>
                </div>
            </div>
            <div v-if="type == 'tag'" class="preset-modal modal-tag">
                <div class="mtg-close" @click="hideModal">
                    <b-icon icon="x"></b-icon>
                </div>
                <div class="mtg-title">Obeleživač</div>
                <div class="mtg-input">
                    <unos
                        style="width: 100%; margin-bottom: 10px"
                        v-model="tagName"
                        vrsta="tekst"
                        velicina="sm"
                        noTitle
                        forceUpdate
                        naslov="Ime obeleživača"
                        @enter="
                            $emit('obelezi', {
                                name: tagName,
                                value: tagValue,
                                format: tagFormat,
                            });
                            tagName = '';
                            tagValue = '';
                            tagFormat = '';
                            tagModalPreviewVariant = 'primary';
                        "
                    />
                    <unos
                        style="width: 100%; margin-bottom: 10px"
                        v-model="tagValue"
                        vrsta="tekst"
                        velicina="sm"
                        noTitle
                        forceUpdate
                        naslov="Vrednost"
                        @enter="
                            $emit('obelezi', {
                                name: tagName,
                                value: tagValue,
                                format: tagFormat,
                            });
                            tagName = '';
                            tagValue = '';
                            tagFormat = '';
                            tagModalPreviewVariant = 'primary';
                        "
                    />
                    <unos
                        style="width: 100%"
                        v-model="tagFormat"
                        vrsta="izbor"
                        :ponuda="[
                            { vrsta: 'string', opis: 'Tekst' },
                            { vrsta: 'number', opis: 'Broj' },
                            { vrsta: 'percent', opis: 'Procenat' },
                        ]"
                        velicina="sm"
                        noTitle
                        forceUpdate
                        naslov="Format"
                        @enter="
                            $emit('obelezi', {
                                name: tagName,
                                value: tagValue,
                                format: tagFormat,
                            });
                            tagName = '';
                            tagValue = '';
                            tagFormat = '';
                            tagModalPreviewVariant = 'primary';
                        "
                    />
                </div>
                <!-- <div class="mtg-variant-picker">
                    <b-button size="sm" :variant="tagModalPreviewVariant"
                        >Primer</b-button
                    >
                    <b-form-select
                        size="sm"
                        v-model="tagModalPreviewVariant"
                        :options="tagModalPreviewOptions"
                    ></b-form-select>
                </div> -->
                <!-- n: {{ tagName }} v: {{ tagValue }} -->
                <div
                    class="modal-button-regular mtg-button"
                    @click="
                        $emit('obelezi', {
                            name: tagName,
                            value: tagValue,
                            format: tagFormat,
                        });
                        tagName = '';
                        tagValue = '';
                        tagModalPreviewVariant = 'primary';
                    "
                >
                    Obeleži
                </div>
                <div
                    v-if="
                        ![null, ''].includes(tagName) &&
                        ![null, ''].includes(tagValue)
                    "
                    class="modal-button-regular"
                    @click="
                        $emit('obelezi', {
                            [tagName]: false,
                        });
                        tagName = '';
                        tagValue = '';
                        tagModalPreviewVariant = 'primary';
                    "
                >
                    Ukloni obeleživač
                </div>
            </div>
            <div
                v-if="type == 'after-send'"
                class="preset-modal modal-after-sent"
            >
                <div class="mas-close" @click="$emit('finish')">
                    <b-icon icon="x"></b-icon>
                </div>
                <div class="mas-title">Uručivanje</div>
                <div class="mas-selector">
                    <b-form-checkbox-group
                        size="sm"
                        v-model="optionSelection"
                        class="mas-selector-options"
                    >
                        <b-form-checkbox
                            v-for="cb in options"
                            :key="cb.id + '-cb'"
                            size="sm"
                            :value="cb.id"
                        >
                            {{ cb.tio }}
                        </b-form-checkbox>
                    </b-form-checkbox-group>
                    <b-form-checkbox
                        class="mas-selector-cb-all"
                        v-model="masSelectAll"
                        size="sm"
                        :indeterminate.sync="indeterminateDocsSelection"
                    >
                        Svi dokumenti
                    </b-form-checkbox>
                </div>
                <div class="mas-archive">
                    <b-form-checkbox
                        class="mas-archive-cb-all"
                        v-model="masArchive"
                        size="sm"
                    >
                        Arhiviraj stare verzije izabranih dokumenata
                    </b-form-checkbox>
                </div>
                <div
                    class="modal-button-regular mas-button"
                    @click="
                        $emit('uruci', {
                            docs: optionSelection,
                            archive: masArchive,
                        })
                    "
                >
                    Uruči
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Objasnjenje from "./Objasnjenje.vue";
import Unos from "./Unos.vue";
export default {
    components: {
        Objasnjenje,
        Unos,
    },
    props: {
        type: {
            type: String,
            default: "response",
        },
        response: {
            type: String,
            default: "Test response",
        },
        responseHint: {
            type: String,
            default: "",
        },
        query: {
            type: String,
            default: "Test query",
        },
        tagInfo: {
            type: Object,
        },
        options: {
            type: Array,
        },
        actionTitle: {
            type: String,
            default: "Action title",
        },
        actionMessage: {
            type: String,
            default: "Additional infromation about the action taking place",
        },
        actionButtons: {
            type: Array,
            default: function () {
                return ["Zatvori"];
            },
        },
    },
    data() {
        return {
            modalVisibility: false,
            highlightedSubject: {},
            masSelectAll: true,
            masArchive: true,
            optionSelection: [],
            tagName: "",
            tagValue: "",
            tagModalPreviewVariant: "primary",
            tagModalPreviewOptions: [
                { value: "primary", text: "Plava" },
                { value: "secondary", text: "Siva" },
                { value: "success", text: "Zelena" },
                { value: "warning", text: "Žuta" },
                { value: "danger", text: "Crvena" },
                { value: "info", text: "Tirkizna" },
                { value: "light", text: "Bela" },
                { value: "dark", text: "Crna" },
            ],
            indeterminateDocsSelection: false,
        };
    },
    watch: {
        masSelectAll: function () {
            if (this.masSelectAll) {
                this.optionSelection = this.options.map((doc) => doc.id);
            } else {
                this.optionSelection = [];
            }
        },
        optionSelection: function () {
            if (this.optionSelection.length == this.options.length) {
                this.masSelectAll = true;
            } else if (this.optionSelection.length == 0) {
                this.masSelectAll = false;
            } else {
                this.indeterminateDocsSelection = true;
            }
        },
    },
    methods: {
        setTagData: function (name, value) {
            this.tagName = name;
            this.tagValue = value;
        },
        highlightSubject: function (newSubject) {
            this.highlightedSubject = { ...newSubject };
        },
        showModal: function () {
            this.modalVisibility = true;
            if (this.type == "after-send") {
                this.optionSelection = this.options.map((doc) => doc.id);
            }
        },
        hideModal: function () {
            this.modalVisibility = false;
        },
        selectNewSubject: function () {
            let query = Object.assign({}, this.$route.query);
            this.$router.push({
                name: this.$route.name,
                params: { mb: this.highlightedSubject.mb },
                query: query,
            });
            this.hideModal();
        },
        copyQueryToClipboard: function () {
            navigator.clipboard.writeText(this.query);
        },
    },
    // updated() {
    //     if (this.tagInfo.tagName != "" && this.type == "tag") {
    //         this.tagName = this.tagInfo.tagName;
    //         this.tagValue = this.tagInfo.tagValue;
    //     }
    // },
};
</script>

<style lang="scss" scoped>
.modal-container {
    .standard-modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 998;
        width: 100vw;
        height: 100vh;
        background: rgba($color: #000000, $alpha: 0.4);
    }

    .standard-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;

        .preset-modal {
            border-radius: 15px !important;
            // overflow: hidden;
            box-shadow: 0px 0px 25px -4px rgba(0, 0, 0, 0.11);
            -webkit-box-shadow: 0px 0px 25px -4px rgba(0, 0, 0, 0.11);
            -moz-box-shadow: 0px 0px 25px -4px rgba(0, 0, 0, 0.11);
        }

        .modal-subject {
            width: 550px;
            height: 300px;
            display: grid;
            grid-template-columns: 40% 60%;

            @media screen and (max-width: 600px) {
                grid-template-columns: 100%;
                width: 90vw;
                height: 450px;
            }

            .panel-subject-highlighted {
                background: rgba(255, 255, 255, 0.3);
                -webkit-backdrop-filter: blur(8px);
                -o-backdrop-filter: blur(8px);
                -moz-backdrop-filter: blur(8px);
                backdrop-filter: blur(8px);
                padding: 15px;
                position: relative;

                .psh-avatar {
                    display: flex;
                    justify-content: center;
                    margin-top: 8px;

                    @media screen and (max-width: 600px) {
                        margin-top: 0;
                    }

                    .psh-avatar-icon {
                        background: var(--clr-gray-300);
                        color: var(--clr-gray-600);
                    }
                }

                .psh-name {
                    display: flex;
                    justify-content: center;
                    font-size: 1.2rem;
                    font-weight: 600;
                    margin-top: 0.5rem;
                    color: var(--clr-primary-900);
                }

                .psh-info {
                    text-align: center;
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translate(-50%);

                    @media screen and (max-width: 600px) {
                        display: flex;
                        gap: 0.5rem;
                        bottom: 8px;
                    }

                    p {
                        margin: 0 !important;
                    }

                    .psh-small-info {
                        font-size: 0.65rem;
                        font-weight: 500;
                        color: var(--clr-primary-900);
                        letter-spacing: 2px;
                        text-transform: uppercase;
                    }

                    .psh-normal-info {
                        color: var(--clr-primary-900);
                    }
                }
            }

            .panel-subject-offer {
                background: var(--clr-gray-300);
                padding: 20px;

                .psh-list {
                    width: 100%;
                    height: 70%;
                    border-radius: 10px;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    margin-top: 2px;

                    .psh-list-item {
                        padding: 0.1rem 0.5rem;
                        cursor: pointer;
                        user-select: none;
                        background: var(--clr-gray-200);
                        color: var(--clr-primary-900);

                        .psh-list-active-item-symbol {
                            color: var(--clr-primary-500);
                            // color: var(--amg-blue);
                        }

                        &:nth-of-type(even) {
                            background: var(--clr-gray-300);
                        }

                        // &:nth-of-type(odd) {
                        //     background: rgba(231, 232, 234, 0.7);
                        // }
                    }

                    .psh-list-item-highlight {
                        background: var(--clr-primary-500) !important;
                        color: var(--clr-white) !important;

                        .psh-list-active-item-symbol {
                            color: var(--clr-white) !important;
                            // color: var(--amg-blue);
                        }
                    }
                }

                .psh-buttons {
                    position: absolute;
                    bottom: 15px;
                    right: 15px;
                    display: flex;
                    gap: 0.6rem;
                }
            }
        }

        .modal-response {
            height: 145px;
            width: 350px;
            background: rgba($color: #e3e5e6, $alpha: 1);
            color: #555a5f;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            @media screen and (max-width: 370px) {
                width: 90vw;
            }

            .mrs-message {
                padding: 0 10px 40px 10px;
                text-align: center;
                // padding-bottom: 40px;
            }

            .mrs-button {
                position: absolute;
                bottom: 15px;
                left: 50%;
                transform: translate(-50%);
            }

            .mrs-hint {
                position: absolute;
                bottom: 13px;
                right: 19px;
            }
        }

        .modal-query {
            height: 245px;
            width: 400px;
            background: rgba($color: #e3e5e6, $alpha: 1);
            color: #555a5f;
            position: relative;
            padding: 15px;

            @media screen and (max-width: 420px) {
                width: 90vw;
            }

            .mqr-buttons {
                position: absolute;
                bottom: 15px;
                left: 50%;
                transform: translate(-50%);
                display: flex;
                gap: 0.6rem;
            }
        }

        .modal-action {
            height: 165px;
            width: 350px;
            background: rgba($color: #e3e5e6, $alpha: 1);
            color: #555a5f;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;

            @media screen and (max-width: 370px) {
                width: 90vw;
            }

            .mac-close {
                position: absolute;
                top: 7px;
                right: 10px;
                cursor: pointer;
            }

            .mac-title {
                position: absolute;
                top: 15px;
                left: 50%;
                transform: translate(-50%);
                text-align: center;
                font-size: 1.15rem;
                font-weight: 500;
                margin-bottom: 15px;
                white-space: nowrap;
            }

            .mac-message {
                text-align: center;
                font-size: 0.8rem;
            }

            .mac-buttons {
                position: absolute;
                bottom: 15px;
                left: 50%;
                transform: translate(-50%);
                display: flex;
                gap: 0.6rem;
            }
        }

        .modal-after-sent {
            height: 250px;
            width: 400px;
            background: rgba($color: #e3e5e6, $alpha: 1);
            color: #555a5f;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 5px;
            padding: 15px;

            .mas-close {
                position: absolute;
                top: 7px;
                right: 10px;
                cursor: pointer;
            }

            .mas-title {
                position: absolute;
                top: 15px;
                left: 50%;
                transform: translate(-50%);
                text-align: center;
                font-size: 1.15rem;
                font-weight: 500;
                margin-bottom: 15px;
                white-space: nowrap;
            }

            .mas-selector {
                height: 115px;
                background: var(--clr-white);
                width: 100%;
                border-radius: 5px;
                border: 1px solid #d0d4d8;
                padding: 2px 5px;
                overflow: hidden;
                position: relative;
                // font-size: 0.8rem;

                .mas-selector-options {
                    height: 74px;
                    width: 100%;
                    padding: 0;
                    overflow-y: auto;
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
                }

                .mas-selector-cb-all {
                    position: absolute;
                    bottom: -2px;
                    left: 50%;
                    transform: translate(-50%);
                }
            }

            .mas-button {
                position: absolute;
                bottom: 15px;
                left: 50%;
                transform: translate(-50%);
            }
        }

        .modal-tag {
            height: 250px;
            width: 400px;
            background: rgba($color: #e3e5e6, $alpha: 1);
            color: #555a5f;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            padding: 15px;

            .mtg-close {
                position: absolute;
                top: 7px;
                right: 10px;
                cursor: pointer;
            }

            .mtg-title {
                position: absolute;
                top: 15px;
                left: 50%;
                transform: translate(-50%);
                text-align: center;
                font-size: 1.15rem;
                font-weight: 500;
                margin-bottom: 15px;
                white-space: nowrap;
            }

            .mtg-input {
                width: 100%;
            }

            .mtg-variant-picker {
                display: flex;
                gap: 10px;
                width: 100%;

                button {
                    margin-top: 1px;
                    height: 29px;
                }

                select {
                    border: 1px solid #e2e4e7;
                }
            }

            .mtg-button {
                position: absolute;
                bottom: 15px;
                left: 50%;
                transform: translate(-50%);
            }
        }
    }
}

.modal-button-regular {
    height: 25px;
    border-radius: 0.25rem;
    background: #f1f2f4;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.3rem;
    cursor: pointer;
    user-select: none;
    border: 1px solid #d0d4d8;
    white-space: nowrap;
}

.modal-button-blue {
    height: 25px;
    border-radius: 0.25rem;
    background: #017aff;
    color: var(--clr-white);
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.3rem;
    cursor: pointer;
    user-select: none;
    border: 1px solid #439eff;
}

.modal-button-red {
    height: 25px;
    border-radius: 0.25rem;
    background: #ff0141;
    color: var(--clr-white);
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.3rem;
    cursor: pointer;
    user-select: none;
    border: 1px solid #f12954;
}

.modal-button-green {
    height: 25px;
    border-radius: 0.25rem;
    background: #07b35d;
    color: var(--clr-white);
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.3rem;
    cursor: pointer;
    user-select: none;
    border: 1px solid #15c25d;
}
</style>