import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        debug: false,
        
        korisnikIme: "",
        korisnikSubjekti: [],
        subjekatIzabrani: {
            mb: "0",
            ime: ""
        },
        knjiga: {
            url: '',
            back: ''
        },
        obrada: {
            komitent: ''
        },
        original: {
            adresa1: "",
            adresa2: "",
            ime: "",
            mb: "",
            pib: "",
            rok: "",
            nacin: "",
            tel: "",
            fax: "",
            email: "",
        },
        messages: []
    }
});