<template>
    <div class="maintenance-view">
        <h1>Amigo će se vratiti uskoro</h1>
        <p class="message">
            Trenutno radimo jako bitne stvari kako bi Amigo što bolje
            funkcionisao i primorani smo da ga privremeno učinimo nedostupnim. U
            najkraćem mogućem roku će Amigo biti vraćen nazad. Hvala Vam na
            razumevanju.
        </p>
        <p class="signature">- Vaš Amigo tim</p>
    </div>
</template>

<script>
import CRUD from "@/crud.js";

export default {
    name: "MaintenanceView",
    data() {
        return {
            maintnanceCheckInterval: null,
        };
    },
    methods: {
        checkIfMaintenanceStillActive: function () {
            new CRUD().read("503").then((response) => {
                if (!(response || {}).data?.greska) {
                    this.$router.push({
                        name: "Login",
                    });
                }
            });
        },
    },
    mounted: function () {
        this.checkIfMaintenanceStillActive();
        this.maintnanceCheckInterval = setInterval(async () => {
            this.checkIfMaintenanceStillActive();
            // .then((response) => {
            //     return response.json();
            // })
            // .then((data) => {
            //     window.console.log("503 maintnatntce", data);
            //     // if (response.ok) {
            //     //     throw new Error("503");
            //     // }
            // });
        }, 300000);
    },
};
</script>

<style lang="scss" scoped>
.maintenance-view {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h1 {
        font-weight: 600;
        color: #42a5f5;
    }

    .message {
        font-size: 0.95rem;
        line-height: 1.45;
        opacity: 0.8;
        max-width: 850px;
        margin: 2rem auto;

        @media (max-width: 870px) {
            width: calc(100vw - 20px);
        }
    }
}
</style>