<template>
    <div
        :class="`xbec__select ${classID} ${label ? 'c-select-labeled' : ''} ${
            filter ? 'c-select-filter' : ''
        }`"
        data-focus-state="inactive"
    >
        <input-component
            type="html"
            :placeholder="placeholder"
            :labeledPlaceholder="labeledPlaceholder"
            :filter="filter"
            readonly
            inline
            v-model="textValue"
            :label="label"
            @click.native="toggleDropdownVisibility($event, $refs.dropdown)"
            :borderless="borderless"
            :background="background"
            :additionalClass="additionalClass"
            clearable
        ></input-component>
        <div ref="dropdown" class="xbec__dropdown">
            <div
                v-for="(option, i) in options.filter((option) => {
                    return !option.hidden;
                })"
                :key="`refkey-${id}-${i}`"
                class="xbec__dropdown__option"
                @click="setNewSelectedOption(option)"
            >
                {{ option.caption }}
            </div>
        </div>
        <!-- <div
            class="xbec__select__dropdown x-bg-white x-shadow-high"
            @mouseleave="toggleFocus()"
        >
            <p
                v-for="(option, i) in options.filter((option) => {
                    return !option.hidden;
                })"
                :key="`option-${i}-${id}`"
                class="xbec__select__option"
                @click="setNewSelectedOption(option)"
            >
                {{ option.caption }}
            </p>
        </div> -->
        <div
            v-if="internalValue != null && !noClear && !filter"
            class="xbec__tag c-clear-input-value-tag x-bg-ultra-light x-shadow-light"
            @click="internalValue = null"
        >
            Poništi
        </div>
    </div>
</template>

<script>
import InputComponent from "@/components/InputComponent.vue";

export default {
    name: "SelectComponent",
    components: {
        InputComponent,
    },
    props: {
        externalValue: {},
        options: {
            type: Array,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: "Izaberite vrednost",
        },
        noClear: {
            type: Boolean,
            default: false,
        },
        borderless: {
            type: Boolean,
            default: false,
        },
        background: {
            type: String,
            default: null,
        },
        independentMode: {
            type: Boolean,
            default: false,
        },
        additionalClass: {
            type: String,
            default: "",
        },
        filter: {
            type: Boolean,
            default: false,
        },
        labeledPlaceholder: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: "externalValue",
        event: "change",
    },
    data() {
        return {
            id: this.generateRandomHex(16),
            textValue: "&zwnj;",

            independentModeValue: null,
        };
    },
    computed: {
        classID: function () {
            return `refid-${this.id}`;
        },
        internalValue: {
            get: function () {
                return this.options.find((option) => {
                    return (
                        (this.independentMode
                            ? this.independentModeValue
                            : this.externalValue) === option.value
                    );
                });
            },
            set: function (newValue) {
                this.textValue = (newValue || {}).caption ?? "&zwnj;";
                if (this.independentMode) {
                    this.independentModeValue = (newValue || {}).value ?? null;
                }
                this.$emit("change", (newValue || {}).value ?? null);
            },
        },
    },
    watch: {
        internalValue: function (newValue) {
            if (newValue) {
                this.textValue = newValue.caption;
            } else {
                this.textValue = "&zwnj;";
            }
        },
    },
    methods: {
        toggleFocus: function (event) {
            window.console.log(event);
            if (event) {
                const { target } = event;
                window.console.log(target.classList);
                if (
                    Array.from(target.classList).includes("xbec__select") &&
                    !this.filter
                ) {
                    window.console.log(event, "ugasen");
                    return;
                }
            }
            const selectElement = document.querySelector(`.${this.classID}`);
            selectElement.dataset.focusState =
                selectElement.dataset.focusState == "inactive"
                    ? "active"
                    : "inactive";
        },
        setNewSelectedOption: function (newOption) {
            window.console.log("snso");
            this.internalValue = newOption;
            document.querySelector(`.${this.classID}`).focusState = "inactive";
        },
    },
    created: function () {
        if (this.externalValue) {
            this.independentModeValue = this.externalValue;
            this.textValue = this.internalValue.caption;
        }
    },
};
</script>

<style lang="scss" scoped>
.c-select-filter {
    &::before {
        display: none;
    }
}
</style>