<template>
    <div v-if="izbor != null" class="sadrzaj-polje-izbor">
        <div class="strelica"></div>
        <b-form-input
            v-if="ponuda.length > 0"
            class="beli-unos unos"
            style="cursor: pointer"
            autocomplete="off"
            readonly
            :size="velicina"
            :placeholder="naslov"
            v-model="radniIzbor"
            :id="'unos-izbor-' + naslov.replace(/\s/g, '')"
        />
        <ponuda-izbora
            v-if="ponuda.length > 0"
            v-show="prikazPonude"
            :ponuda="ponuda"
            :velicina="velicina"
            @izbor="spoljniUnos"
        />
    </div>
</template>

<script>
import PonudaIzbora from "./PoljeUnosaIzbor/PonudaIzbora";

export default {
    name: "PoljeUnosaIzbor",
    components: {
        PonudaIzbora,
    },
    props: {
        ponuda: {
            type: Array,
        },
        naslov: {
            type: String,
            default: "Naslov unosa",
        },
        velicina: {
            type: String,
            default: "md",
            validator: function (vrednost) {
                return ["sm", "md", "lg"].includes(vrednost);
            },
        },
    },
    data() {
        let iz = this.ponuda[0];
        return {
            izbor: iz,
            prikazPonude: false,
        };
    },
    watch: {
        izbor: function () {
            this.$emit("promena-unosa", this.izbor);
        },
    },
    computed: {
        radniIzbor: function () {
            this.izbor;
            if (this.izbor.vrsta != null) {
                return this.izbor.opis;
            } else {
                return "";
            }
        },
    },
    methods: {
        spoljniUnos: function ($$vrednost) {
            try {
                if (typeof ($$vrednost || {}).vrsta == "undefined") {
                    for (let i = 0; i <= this.ponuda.length - 1; i++) {
                        if (this.ponuda[i].vrsta == $$vrednost) {
                            this.izbor = Object.assign({}, this.ponuda[i]);
                        }
                    }
                } else {
                    this.izbor = $$vrednost;
                }
                this.prikazPonude = false;
            } catch (msg) {
                console.log(this.naslov, $$vrednost, msg);
            }
        },
        prikaziPonudu: function () {
            this.prikazPonude = true;
        },
        ukloniPonudu: function (e) {
            if (!this.$el.contains(e.target)) {
                this.prikazPonude = false;
            }
        },
        omoguciPonudu: function (el) {
            if (el == null) {
                el = document.getElementById(
                    "unos-izbor-" + this.naslov.replace(/\s/g, "")
                );
            }
            el.addEventListener("focusin", this.prikaziPonudu);
        },
        osvezi: function () {
            if (typeof this.izbor.vrsta != "undefined") {
                this.$emit("promena-unosa", this.izbor);
            }
        },
    },
    mounted() {
        document.addEventListener("click", this.ukloniPonudu);
        let el = document.getElementById(
            "unos-izbor-" + this.naslov.replace(/\s/g, "")
        );
        if (el != null) {
            this.omoguciPonudu(el);
        } else {
            setTimeout(
                function () {
                    this.omoguciPonudu(el);
                }.bind(this, el),
                1000
            );
        }
        setTimeout(
            function () {
                this.osvezi();
            }.bind(this),
            250
        );
    },
    beforeDestroy() {
        document.removeEventListener("click", this.ukloniPonudu);
    },
};
</script>

<style lang="scss" scoped>
.sadrzaj-polje-izbor {
    position: relative;
    margin: 0 !important;
    cursor: pointer !important;

    .strelica {
        position: absolute;
        top: 50%;
        right: 0.75rem;
        transform: translate(0, -50%);
        background: #9097a0;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        height: 7px;
        width: 10px;
        z-index: 900;
        cursor: pointer;
    }

    .beli-unos {
        background: var(--clr-white) !important;
    }
}
</style>