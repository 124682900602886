<template>
    <div :class="'okvir-ponude-izbora ' + velicina">
        <table v-if="ponuda.length > 0" class="tabela-izbora pointer">
            <div class="div-ponuda" v-for="p in ponuda" :key="p.vrsta">
                <div class="red-ponuda" @click="izbor(p)">
                    <span>
                        {{ p.opis }}
                    </span>
                </div>
            </div>
        </table>
    </div>
</template>

<script>
export default {
    name: "PonudaIzbora",
    props: {
        ponuda: {
            type: Array,
        },
        velicina: {
            type: String,
        },
    },
    methods: {
        izbor: function ($$vrednost) {
            this.$emit("izbor", $$vrednost);
        },
    },
};
</script>

<style lang="scss" scoped>
.okvir-ponude-izbora {
    position: absolute;
    z-index: 1500;
    max-height: 300px;
    width: 100%;
    left: 0%;
    background: var(--clr-white);
    border-radius: 0 0 0.25rem 0.25rem;
    overflow-y: scroll;
    box-shadow: 1px 3px 11px 0px rgba(0, 0, 0, 0.04);

    .tabela-izbora {
        width: 100%;

        .red-ponuda {
            width: 100%;
            padding: 0.375rem 0.75rem;

            &:hover {
                background: #f4f7f9;
            }
        }
    }
}

.top-sm {
    top: 31px;
}

.top-md {
    top: 38px;
}
// .tabela-izbora {
//     width: 100%;
// }
// .red-ponude-izbora {
//     padding: .4rem 1rem;
//     font-weight: 350;
//     color: #495057;
// }
// td:hover {
//     background: #f7f6f6;
// }
// td:active {
//     background: #eeeded;
// }
</style>