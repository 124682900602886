<template>
    <transition name="fade">
        <div v-if="vidljiv" class="modal-skup">
            <div class="modal-pozadina" @click="zatvoriModal()"></div>
            <div class="modal-ploca">
                <modal-naslov :naslov="naslov" :boja="boja" :znak="znak" />
                <div class="modal-telo text-muted">
                    {{ tekst }}
                </div>
                <modal-dugmad
                    :d1="d1"
                    :d1_v="d1_v"
                    :d2="d2"
                    :d2_v="d2_v"
                    :d3="d3"
                    :d3_v="d3_v"
                    :dp="dp"
                    :dp_v="dp_v"
                    :d1auto="d1auto"
                    @d1_c="$emit('d1_c')"
                    @d2_c="$emit('d2_c')"
                    @d3_c="$emit('d3_c')"
                    @dp_c="$emit('dp_c')"
                    @zatvori="zatvoriModal()"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import ModalNaslov from "./ModalNaslov";
import ModalDugmad from "./ModalDugmad";

export default {
    components: {
        ModalNaslov,
        ModalDugmad,
    },
    props: {
        d1auto: {
            type: Boolean,
            default: true,
        },
        znak: {
            type: String,
            default: "bez znaka",
        },
        naslov: {
            type: String,
            default: "Osnovni modal",
        },
        tekst: {
            type: String,
            default: "Ovo je osnovni tekst podloge koji popunjava ovo mesto.",
        },
        boja: {
            type: String,
            default: "secondary",
        },
        d1: {
            type: String,
            default: "0",
        },
        d2: {
            type: String,
            default: "0",
        },
        d3: {
            type: String,
            default: "0",
        },
        dp: {
            type: String,
            default: "0",
        },
        d1_v: {
            type: String,
            default: "light",
        },
        d2_v: {
            type: String,
            default: "light",
        },
        d3_v: {
            type: String,
            default: "light",
        },
        dp_v: {
            type: String,
            default: "secondary",
        },
    },
    data() {
        return {
            vidljiv: false,
        };
    },
    methods: {
        prikaziModal: function () {
            this.vidljiv = true;
        },
        zatvoriModal: function () {
            this.vidljiv = false;
        },
    },
};
</script>

<style>
/* 
Služi da sakrije scrollbar kada se aktivira modal, da bi radilo mora
biti podešeno da style nije scoped zbog čega je u odvojenom style bloku: NaN;

Ovaj style blok NE dirati!
*/
/* *::-webkit-scrollbar {
  display: none !important;
} */
</style>

<style scoped>
.modal-skup {
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
}
.modal-pozadina {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.modal-ploca {
    position: absolute;
    z-index: 10000;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 13rem;
    width: 30rem;
    background-color: var(--clr-white);
    border-radius: 0.25rem;
}
.modal-telo {
    padding: 0 1.2rem;
    margin: auto 0;
}
</style>

<style scoped>
/* 
Koristi se za fade in i fade out kada se prikazuje/sakriva modal
*/
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>