<template>
    <div>
        <!-- broj: {{broj}}<br>
        novac: {{novac}}<br>
        vrednost: {{vrednost}}<br>
        valuta: {{valuta}} -->
        <b-input-group>
            <template v-if="prepend != null" #prepend>
                <b-input-group-text class="prepend">{{
                    prepend
                }}</b-input-group-text>
            </template>
            <b-form-input
                :readonly="!promenljiv"
                autocomplete="off"
                type="text"
                :placeholder="providan == true ? '' : naslov"
                v-model="broj"
                :id="'unos-tekst-' + naslov.replace(/\s/g, '')"
                :class="klasa + ' text-right'"
                @focus="pripremiZaUnos"
                @blur="prikaziNovcano"
                @keypress="unosVrednosti($event)"
            />
            <b-input-group-append v-if="dopis != ''">
                <b-input-group-text>
                    {{ dopis }}
                </b-input-group-text>
            </b-input-group-append>
        </b-input-group>
    </div>
</template>

<script>
export default {
    name: "PoljeUnosaBroj",
    props: {
        naslov: {
            type: String,
            default: "Naslov unosa",
        },
        valuta: {
            type: String,
            default: "",
        },
        providan: {
            type: Boolean,
        },
        promenljiv: {
            type: Boolean,
        },
        dopis: {
            type: String,
            default: "",
        },
        prepend: {
            type: String,
        },
    },
    data() {
        let br = this.$parent.value;
        return {
            broj: br,
            vrednost: null,

            // zabrana: false
        };
    },
    computed: {
        novac: function () {
            let rez = this.uNovac(this.vrednost, true);
            rez += this.valuta !== "" ? " " + this.valuta.toUpperCase() : "";
            return rez;
        },
        klasa: function () {
            return this.providan == true ? "unos-providan" : "";
        },
    },
    watch: {
        broj: function () {
            try {
                let x = this.broj.split(".").join("");
                this.vrednost = parseFloat(x.split(",").join("."));
                this.$emit("promena-unosa", this.vrednost);
            } catch (error) {
                //
            }
        },
    },
    methods: {
        test(msg) {
            console.log(msg);
        },
        spoljniUnos: function ($$vrednost) {
            if (this.uNovac(this.vrednost) != this.uNovac($$vrednost)) {
                this.vrednost = $$vrednost;
                this.broj = this.novac;
            }
        },
        pripremiZaUnos: function () {
            this.broj = this.vrednost;
        },
        prikaziNovcano: function () {
            this.broj = this.novac;
        },
        unosVrednosti: function (e) {
            if (e.keyCode == 44 || (47 < e.keyCode && e.keyCode < 58)) {
                this.vrednost = this.broj.toString() != "" ? this.broj : 0;
            } else {
                e.preventDefault();
            }
        },
    },
    mounted() {
        this.vrednost = this.broj;
        this.prikaziNovcano();
    },
};
</script>

<style scoped>
.unos-providan {
    background-color: transparent !important;
    border: 0px;
}
</style>