<template>
    <div class="login-container">
        <div class="logo">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="128.329"
                height="112.542"
                viewBox="0 0 258.329 112.542"
            >
                <g id="amigo-logo-white" transform="translate(-0.002 0)">
                    <path
                        id="Subtraction_1"
                        data-name="Subtraction 1"
                        d="M48.877,131.126h0c-8.741,0-15.9-2.136-20.7-6.178-8.589-7.234-9.822-20.356-9.346-30.091a108.606,108.606,0,0,1,9.87-38.441A79.785,79.785,0,0,1,48.782,28.772c7.9-6.666,16.51-10.188,24.907-10.188h.642a34.7,34.7,0,0,1,12.711,2.872,45.746,45.746,0,0,1,11.7,7.335A55.26,55.26,0,0,0,59.888,54.1,83.551,83.551,0,0,0,48,88.1c-1.383,12.231.793,21.579,5.97,25.646a14.624,14.624,0,0,0,9.468,2.809c11.323,0,27.845-7.545,45.206-15.947a58.308,58.308,0,0,1-12.107,13.2,79.649,79.649,0,0,1-22.756,12.573,74.372,74.372,0,0,1-24.9,4.741Z"
                        transform="translate(-18.745 -18.584)"
                        fill="#009fff"
                    />
                    <text
                        id="amigo"
                        transform="translate(45.331 73.005)"
                        fill="#24344b"
                        font-size="65"
                        font-family="LogoFont"
                        font-weight="800"
                    >
                        <tspan x="0" y="0">amigo</tspan>
                    </text>
                </g>
            </svg>
        </div>
        <div id="animated-gradient">
            <div class="title">
                <p class="title-small">Vaš poslovni</p>
                <p class="title-main">Ultrapomoćnik</p>
                <div class="title-tags">
                    <div class="title-tag">
                        <b-icon icon="box" scale="1.3"></b-icon>
                        <span>Jednostavan</span>
                    </div>
                    <div class="title-tag">
                        <b-icon icon="check2-circle" scale="1.3"></b-icon>
                        <span>Pouzdan</span>
                    </div>
                    <div class="title-tag">
                        <b-icon icon="lightning-fill" scale="1.3"></b-icon>
                        <span>Brz</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="login-side">
            <div class="login-panel">
                <!-- <div class="version">
                    <span>v 0.3.1</span>
                </div> -->
                <div class="prijava">
                    <span>Prijava</span>
                </div>
                <!-- <div class="logo">
                    <svg
                        id="amigo-logo"
                        xmlns="http://www.w3.org/2000/svg"
                        width="151"
                        height="64.547"
                        viewBox="0 0 151 64.547"
                    >
                        <path
                            id="Subtraction_1"
                            data-name="Subtraction 1"
                            d="M36.028,83.13h0c-5.013,0-9.118-1.225-11.87-3.543-4.926-4.149-5.633-11.675-5.36-17.258a62.289,62.289,0,0,1,5.661-22.047A45.759,45.759,0,0,1,35.973,24.427c4.53-3.823,9.469-5.843,14.285-5.843.122,0,.246,0,.368,0a19.9,19.9,0,0,1,7.29,1.647,26.237,26.237,0,0,1,6.713,4.207A31.693,31.693,0,0,0,42.343,38.956a47.919,47.919,0,0,0-6.82,19.5c-.793,7.015.455,12.376,3.424,14.709a8.387,8.387,0,0,0,5.43,1.611c6.494,0,15.97-4.327,25.927-9.146A33.441,33.441,0,0,1,63.36,73.2a45.682,45.682,0,0,1-13.051,7.211A42.655,42.655,0,0,1,36.028,83.13Z"
                            transform="translate(-18.745 -18.584)"
                            fill="#009fff"
                        />
                        <text
                            id="amigo"
                            transform="translate(26 42.591)"
                            fill="#24344b"
                            font-size="38"
                            font-family="Mont-HeavyDEMO, Mont DEMO"
                            font-weight="800"
                        >
                            <tspan x="0" y="0">amigo</tspan>
                        </text>
                    </svg>
                </div> -->
                <div class="inputs">
                    <unos
                        class="login-input"
                        v-model="loginInfo.email"
                        velicina="sm"
                        textInputType="email"
                        vrsta="tekst"
                        naslov="Email"
                    />
                    <unos
                        class="login-input"
                        v-model="loginInfo.password"
                        velicina="sm"
                        textInputType="password"
                        vrsta="tekst"
                        naslov="Lozinka"
                    />
                </div>
                <div
                    class="button-dark button-sm login-button"
                    @click="login()"
                >
                    Prijavi se
                </div>
                <div
                    v-if="errorHappened"
                    class="xbec__panel c-login-error-panel"
                >
                    <p>Ne postoji uneta kombinacija email adrese i lozinke</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import LPloca from "../components/Login/LPloca";
// import RPloca from "../components/Login/RPloca";
import Unos from "../components/Unos.vue";

export default {
    name: "Login",
    components: {
        Unos,
    },
    data() {
        return {
            loginInfo: {
                email: "",
                password: "",
            },
            errorHappened: false,
        };
    },
    methods: {
        login: function () {
            if (this.loginInfo.email != "" && this.loginInfo.password != "") {
                axios
                    .post(
                        process.env.VUE_APP_API_URL + "login",
                        JSON.stringify({
                            username: this.loginInfo.email,
                            password: this.loginInfo.password,
                        })
                    )
                    .then((response) => {
                        window.console.log(response);
                        if (response.data.greska == false) {
                            let korisnik = {};
                            korisnik.ime = response.data.korisnik.ime;
                            korisnik.subjekti = response.data.korisnik.subjekti;
                            korisnik.referal = response.data.korisnik.referal;
                            korisnik.token = response.data.korisnik.token;
                            korisnik.M = response.data.korisnik.M;
                            korisnik.uuid = response.data.korisnik.uuid;
                            localStorage.setItem(
                                "korisnik",
                                JSON.stringify(korisnik)
                            );
                            this.$router.push({
                                name: "Pregled",
                                params: {
                                    mb: response.data.korisnik.subjekti[0].mb,
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 503) {
                            window.location.href = "maintenance";
                            // this.$router.push({ name: "MaintenanceView" });
                        }
                        this.errorHappened = true;
                    });
            } else {
                this.greska = "input";
                console.log(this.loginInfo);
            }
        },
    },
    mounted() {
        setInterval(rotateBackroundGradient, 30);
        var i = 0;
        var divGradient = document.getElementById("animated-gradient");
        function rotateBackroundGradient() {
            i++;
            divGradient.style.backgroundImage =
                "linear-gradient(" +
                i +
                "deg,rgba(36, 52, 75,0.25), rgba(0, 159, 255,0.5)), linear-gradient(0deg, white, rgba(0, 159, 255,0.5))";
            return i;
        }
    },
};
</script>


<style lang="scss" scoped>
.login-container {
    display: flex;
    // width: 100vw;
    height: 100vh;
    background: rgb(255, 255, 255);

    .logo {
        position: absolute;
        z-index: 5;
        left: 8rem;
        top: 1.5rem;
        // opacity: 0.5;
    }

    #animated-gradient {
        z-index: 1;
        display: flex;
        align-items: center;
        overflow: visible;
        width: 66%;
        height: 100%;
        border-radius: 0 50px 50px 0;
        opacity: 0.7;
        box-shadow: 9px 0px 26px 0px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 9px 0px 26px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 9px 0px 26px 0px rgba(0, 0, 0, 0.1);
        // background: cornflowerblue;

        .title {
            margin-left: 8rem;
            color: white;

            .title-small {
                font-size: 62px;
                font-weight: 600;
                margin-bottom: -2rem;
            }

            .title-main {
                font-size: 103px;
                font-weight: 700;
                margin-bottom: -1rem;
                text-transform: uppercase;
            }

            .title-tags {
                display: flex;
                gap: 0.5rem;

                .title-tag {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // height: 48px;
                    gap: 0.5rem;
                    width: fit-content;
                    padding: 7px 10px;
                    border-radius: 10px;
                    background: rgba(255, 255, 255, 0.25);
                    // opacity: 0.25;

                    svg {
                        margin-left: 0.3rem;
                    }
                }
            }
        }
    }

    .login-side {
        position: relative;
        display: flex;
        // justify-content: center;
        align-items: center;
        height: 100vh;
        width: calc(100% - 66%);
        padding: 0 8rem;

        .version {
            position: absolute;
            display: flex;
            justify-content: center;
            width: calc(100% - 16rem);
            top: 4.5rem;
            left: 50%;
            transform: translate(-50%);
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 16px;
            color: var(--amg-gray);

            span {
                border-bottom: 3px solid var(--amg-gray);
            }
        }

        .prijava {
            position: absolute;
            display: flex;
            justify-content: center;
            // width: 100%;
            width: calc(100% - 16rem);
            top: -1rem;
            left: 50%;
            transform: translate(-50%);
            font-size: 3.3rem;
            font-weight: 700;
            color: var(--amg-dark);
        }

        .login-panel {
            position: relative;
            width: 100%;

            .inputs {
                margin: 4rem 0 1.5rem 0;
            }

            .login-button {
                margin: 0 5rem;
            }

            .c-login-error-panel {
                border: 1px solid #f1f1f1;
                text-align: center;
                position: absolute;
                top: 110%;
                left: 50%;
                transform: translateX(-50%);
                width: 120%;
                p {
                    margin: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1650px) {
    .title-small {
        font-size: 3vw !important;
        // font-weight: 600;
        margin-bottom: -2.5vw !important;
    }

    .title-main {
        font-size: 5vw !important;
        // font-weight: 700;
        // margin-bottom: -1rem;
        // text-transform: uppercase;
    }
}

@media screen and (max-width: 1500px) {
    .title-tag {
        padding: 3px 5px !important;
    }

    .login-button {
        margin: 0 2rem !important;
    }
}

@media screen and (max-width: 1250px) {
    .title-tag {
        padding: 3px 5px !important;
    }

    .login-side {
        padding: 0 4rem !important;
    }

    .login-button {
        margin: 0 2rem !important;
    }
}

@media screen and (max-width: 1500px) {
    .logo {
        left: 4rem !important;
    }
    .title {
        margin-left: 4rem !important;
    }
    .title-tag {
        padding: 1px 3px !important;
        border-radius: 6px !important;
        margin-top: 0.5rem;
        font-size: 0.8rem;

        svg {
            margin-left: 0.3rem;
        }
    }
}

@media screen and (max-width: 800px) {
    #animated-gradient {
        display: none !important;
    }

    .login-side {
        width: 100% !important;
        padding: 0 10rem !important;
    }
}

@media screen and (max-width: 600px) {
    .login-side {
        width: 100% !important;
        padding: 0 7rem !important;
    }
}

@media screen and (max-width: 450px) {
    .login-side {
        width: 100% !important;
        padding: 0 2rem !important;
    }
}
</style>