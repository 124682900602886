<template>
    <div
        ref=""
        :class="`${classID} xbec__input ${label && 'c-input-labeled'} ${
            labeledPlaceholder && 'c-input-labeled-placeholder'
        } ${filter && 'c-input-filter'} ${
            multiSelect && 'c-input-multi-select'
        }`"
        :data-placeholder="
            [null, '', '&zwnj;'].includes(
                independentMode ? independentModeValue : internalValue
            )
                ? placeholder
                : ''
        "
        :style="`${
            ![null, '', '&zwnj;'].includes(
                independentMode ? independentModeValue : internalValue
            ) &&
            clearable &&
            filter
                ? 'padding-right: 36px'
                : ''
        }`"
        @click="test($event)"
        @blur="input.classList.remove('x-active')"
    >
        <label v-if="label" class="xbec__input__label"
            >{{ label }}
            <span
                style="color: var(--clr-primary-900)"
                v-if="
                    labeledPlaceholder &&
                    ![null, '', '&zwnj;'].includes(
                        independentMode ? independentModeValue : internalValue
                    )
                "
                >{{ externalValue }}</span
            ></label
        >
        <html-input-component
            v-if="type == 'html'"
            :class="`xbec__input__field ${borderless && 'c-borderless'}`"
            v-model="internalValue"
            :inline="inline"
            :textarea="textarea"
            :readonly="readonly"
            :background="background"
            :additionalClass="additionalClass"
            :style="`${
                [null, '', '&zwnj;'].includes(
                    independentMode ? independentModeValue : internalValue
                ) &&
                labeledPlaceholder &&
                multiSelect
                    ? 'display:none'
                    : ''
            }`"
            @blur.native="test($event)"
        ></html-input-component>
        <numeric-input-component
            v-if="type == 'numeric'"
            :class="`xbec__input__field ${borderless && 'c-borderless'}`"
            v-model="internalValue"
            :decimalPoints="decimalPoints"
            :prepend="prepend"
            :append="append"
            :readonly="readonly"
            :min="min"
            :max="max"
            :background="background"
            :additionalClass="additionalClass"
            :style="`${
                [null, '', '&zwnj;'].includes(
                    independentMode ? independentModeValue : internalValue
                )
                    ? 'display:none'
                    : ''
            }`"
        ></numeric-input-component>
    </div>
</template>

<script>
import HtmlInputComponent from "./HtmlInputComponent";
import NumericInputComponent from "./NumericInputComponent";
export default {
    name: "InputComponent",
    components: {
        HtmlInputComponent,
        NumericInputComponent,
    },
    props: {
        externalValue: {},
        label: {
            type: String,
            default: null,
        },
        type: {
            type: String,
        },
        inline: {
            type: Boolean,
        },
        placeholder: {
            type: String,
            default: null,
        },
        textarea: {
            type: Boolean,
        },
        borderless: {
            type: Boolean,
            default: false,
        },
        decimalPoints: {
            type: Number,
            default: 2,
        },
        prepend: {
            type: String,
            default: null,
        },
        append: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        background: {
            type: String,
            default: "white",
        },
        independentMode: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            default: null,
        },
        max: {
            type: Number,
            default: null,
        },
        debounce: {
            type: Number,
            default: null,
        },
        additionalClass: {
            type: String,
            default: "",
        },
        filter: {
            type: Boolean,
            default: false,
        },
        labeledPlaceholder: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        multiSelect: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: "externalValue",
        event: "update",
    },
    data() {
        return {
            id: this.generateRandomHex(24),
            independentModeValue: null,
            debounceTimeout: null,
        };
    },
    computed: {
        classID: function () {
            return `js-ref-${this.id}`;
        },
        internalValue: {
            get: function () {
                return this.externalValue;
            },
            set: function (value) {
                if (this.independentMode) {
                    this.independentModeValue = value;
                }
                if (this.debounce) {
                    clearTimeout(this.debounceTimeout);
                    this.debounceTimeout = setTimeout(() => {
                        this.emitUpdate(value);
                    }, this.debounce);
                } else {
                    this.emitUpdate(value);
                }
            },
        },
    },
    methods: {
        test: function () {
            const input = document.querySelector(`.${this.classID}`);
            window.console.log(
                input,
                Array.from(input.classList).includes("x-active")
            );
            if (!this.multiSelect && this.filter) {
                if (Array.from(input.classList).includes("x-active")) {
                    input.classList.remove("x-active");
                } else {
                    input.classList.add("x-active");
                    input.children[1].focus();
                }
            }
        },
        emitUpdate: function (value) {
            this.$emit("update", value);
        },
    },
    created: function () {
        if (this.externalValue) {
            this.independentModeValue = this.externalValue;
        }
    },
};
</script>

<style lang="scss">
.c-input-filter {
    background-color: var(--clr-white);
    // border: 1px solid var(--clr-gray-500);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    width: fit-content;
    min-width: fit-content;

    label {
        pointer-events: none;
    }

    &:not(.c-input-multi-select) .xbec__input__html,
    .xbec__input__numeric {
        display: none;
    }

    .xbec__input__field {
        border: 0 !important;
        min-height: 32px !important;
    }
}

.c-input-filter.x-active {
    // padding: 0;

    label span {
        display: none;
    }

    .xbec__input__html,
    .xbec__input__numeric {
        display: initial;
        outline: none !important;
    }
}

.c-input-labeled-placeholder {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0 8px;
    cursor: pointer !important;
    height: 34px;

    &::before {
        display: none;
    }

    label {
        font-size: 1rem !important;
        margin-bottom: 0 !important;
        cursor: pointer !important;
        color: var(--clr-gray-600);
    }

    .xbec__input__field {
        padding-left: 0 !important;
    }
}
</style>