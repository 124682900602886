<template>
    <div>
        <modal-component
            ref="supportModal"
            title="Podrška"
            :actions="[
                {
                    caption: 'Zatvori',
                    eventName: 'close',
                },
                {
                    caption: 'Pošalji',
                    eventName: 'send',
                },
            ]"
            @close="hideModal()"
            @send="sendSupportTicket()"
        >
            <template>
                <select-component
                    label="Razlog kontaktiranja"
                    v-model="supportTicketData.reason"
                    :options="supportReasonOptions"
                    noClear
                ></select-component>
                <input-component
                    type="html"
                    v-model="supportTicketData.message"
                    label="Poruka"
                    textarea
                ></input-component>
                <div
                    class="
                        xbec__panel
                        c-support-contact
                        x-bg-ultra-light x-shadow-light
                    "
                >
                    <p class="xbec__panel__title x-fg-light">
                        Kontakt podaci podrške
                    </p>
                    <ul class="xbec__list">
                        <li>podrska@amigo.cloud</li>
                    </ul>
                </div>
            </template>
        </modal-component>
    </div>
</template>

<script>
import CRUD from "@/crud.js";
import ModalComponent from "@/components/ModalComponent.vue";
import SelectComponent from "@/components/SelectComponent.vue";
import InputComponent from "@/components/InputComponent.vue";

export default {
    name: "SupportModalComponent",
    components: {
        ModalComponent,
        SelectComponent,
        InputComponent,
    },
    data() {
        return {
            supportReasonOptions: [
                {
                    caption: "Prijava problema/greške",
                    value: "bug",
                },
                {
                    caption: "Predlog za poboljšanje",
                    value: "suggestion",
                },
                {
                    caption: "Pomoć pri korišćenju",
                    value: "help",
                },
                {
                    caption: "Dokumenti/Šabloni",
                    value: "docs_and_templates",
                },
                {
                    caption: "Podaci subjekta",
                    value: "account_data",
                },
                {
                    caption: "Nedostaju podaci",
                    value: "missing_data",
                },
                {
                    caption: "Podešavanje bankovnih računa",
                    value: "bank_missing_data",
                },
                {
                    caption: "Podešavanje prosleđivanja izvoda",
                    value: "invoice_forwarding",
                },
                {
                    caption: "Ostalo",
                    value: "general",
                },
            ],
            supportTicketData: {
                reason: null,
                message: null,
            },
        };
    },
    methods: {
        showModal(initialReason = null) {
            if (initialReason) this.supportTicketData.reason = initialReason;
            this.$refs.supportModal.showModal();
        },
        hideModal: function () {
            this.supportTicketData = {
                reason: null,
                message: null,
            };
            this.$refs.supportModal.hideModal();
        },
        sendSupportTicket: function () {
            if (
                [null, "", "&zwnj;"].includes(this.supportTicketData.reason) ||
                [null, "", "&zwnj;"].includes(this.supportTicketData.message)
            ) {
                this.generateMessage(
                    "failure",
                    "Morate popuniti oba polja pre slanja.",
                    false
                );
                return;
            }
            let { chainReference } = this.generateMessage(
                "awaiting",
                "Slanje poruke podršci...",
                false
            );
            new CRUD()
                .create("podrska", JSON.stringify(this.supportTicketData))
                .then((response) => {
                    this.generateMessage(
                        "success",
                        "Uspešno poslata poruka podršci, u najbržem roku će Vas neko kontaktirati.",
                        true,
                        chainReference
                    );
                    window.console.log("support API response", response);
                })
                .catch((error) => {
                    this.generateMessage(
                        "failure",
                        "Greška prilikom slanja poruke, pokušajte ponovo.",
                        true,
                        chainReference
                    );
                    window.console.log("support API error", error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.c-support-contact {
    margin-top: 20px;
    text-align: center;

    li {
        font-weight: 500;
    }
}
</style>