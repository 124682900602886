import Vue from "vue";

var dr = {
    methods: {
        getElementFromId: function (id) {
            try {
                return document.getElementById(id);
            }
            catch (exception) {
                console.log(exception);
            }
        }
    }
};

var documentReader = Vue.extend({
    mixins: [dr]
});

export default documentReader;