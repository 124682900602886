<template>
    <div>
        <b-button
            :class="vrsta"
            :block="block"
            @click="pritisak()"
        >
            <b-icon
                v-if="slika != 'blank'"
                :icon="slika"
            />
            {{naslov}}
        </b-button>
    </div>
</template>

<script>

export default {
    name: "PoljeUnosaTekst",
    props: {
        vrsta: {
            type: String,
            default: 'jaki',
            validator: function ($$vrednost) {
                return ['jaki', 'obradni', 'slabi'].indexOf($$vrednost) !== -1
            }
        },
        block: {
            type: Boolean,
            default: false
        },
        slika: {
            type: String,
            default: 'blank'
        },
        naslov: {
            type: String,
            default: 'Naslov dugmeta'
        }
    },
    methods: {
        pritisak: function() {
            this.$emit('pritisak');
        }
    }
}
</script>

<style scoped>
.jaki {
    background-image: linear-gradient(142deg, #3a47d5 0%, #0099ff 100%);
    border: none !important;
    background-size: 150% auto;
    transition: 0.5s;
    -webkit-box-shadow: 0px 7px 15px -5px rgba(0, 0, 0, 0.08) !important;
    box-shadow: 0px 7px 15px -5px rgba(0, 0, 0, 0.08) !important;
}
.jaki:hover {
    background-position: right center;
    text-decoration: none;
}
.obradni {
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    border: none !important;
    font-weight: 500;
    background-size: 150% auto;
    transition: 0.5s;
    -webkit-box-shadow: 0px 7px 15px -5px rgba(0, 0, 0, 0.08) !important;
    box-shadow: 0px 7px 15px -5px rgba(0, 0, 0, 0.08) !important;
}
.obradni:hover {
    background-position: right center;
    text-decoration: none;
}
.slab {
    color: slategrey;
    background: white;
    border: none !important;
    -webkit-box-shadow: 0px 7px 15px -5px rgba(0, 0, 0, 0.08) !important;
    box-shadow: 0px 7px 15px -5px rgba(0, 0, 0, 0.08) !important;
}
</style>