<template>
    <div class="modal-dugmad-okvir">
        <div class="modal-dugmad m-0 p-0">
            <b-button v-if="d1 !== '0'" :variant="d1_v" size="sm" @click="d1_c()">{{d1}}</b-button>&nbsp;
            <b-button v-if="d2 !== '0'" :variant="d2_v" size="sm" @click="d2_c()">{{d2}}</b-button>&nbsp;
            <b-button v-if="d3 !== '0'" :variant="d3_v" size="sm" @click="d3_c()">{{d3}}</b-button>&nbsp;
            <b-button v-if="dp !== '0'" :variant="dp_v" size="sm" @click="dp_c()">{{dp}}</b-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        d1auto: {
            type: Boolean,
            default: true
        },
        d1: {
            type: String,
            default: '0',
        },
        d2: {
            type: String,
            default: '0',
        },
        d3: {
            type: String,
            default: '0',
        },
        dp: {
            type: String,
            default: '0',
        },
        d1_v: {
            type: String,
            default: 'light',
        },
        d2_v: {
            type: String,
            default: 'light',
        },
        d3_v: {
            type: String,
            default: 'light',
        },
        dp_v: {
            type: String,
            default: 'secondary',
        }
    },
    methods: {
        d1_c: function() {
            if (!this.d1auto) {
                this.$emit('d1_c');
            }
            else {
                this.$emit('zatvori');
            }
        },
        d2_c: function() {
            this.$emit('d2_c');
        },
        d3_c: function() {
            this.$emit('d3_c');
        },
        dp_c: function() {
            console.log('modal dugmad')
            this.$emit('dp_c');
        }
    }
};
</script>

<style scoped>
.modal-dugmad-okvir {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3.5rem;
}
.modal-dugmad {
    position: absolute;
    top: 50%;
    right: .75rem;
    transform: translate(0, -50%);
}
</style>