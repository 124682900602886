var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"",class:(_vm.classID + " xbec__input " + (_vm.label && 'c-input-labeled') + " " + (_vm.labeledPlaceholder && 'c-input-labeled-placeholder') + " " + (_vm.filter && 'c-input-filter') + " " + (_vm.multiSelect && 'c-input-multi-select')),style:(("" + (![null, '', '&zwnj;'].includes(
            _vm.independentMode ? _vm.independentModeValue : _vm.internalValue
        ) &&
        _vm.clearable &&
        _vm.filter
            ? 'padding-right: 36px'
            : ''))),attrs:{"data-placeholder":[null, '', '&zwnj;'].includes(
            _vm.independentMode ? _vm.independentModeValue : _vm.internalValue
        )
            ? _vm.placeholder
            : ''},on:{"click":function($event){return _vm.test($event)},"blur":function($event){return _vm.input.classList.remove('x-active')}}},[(_vm.label)?_c('label',{staticClass:"xbec__input__label"},[_vm._v(_vm._s(_vm.label)+" "),(
                _vm.labeledPlaceholder &&
                ![null, '', '&zwnj;'].includes(
                    _vm.independentMode ? _vm.independentModeValue : _vm.internalValue
                )
            )?_c('span',{staticStyle:{"color":"var(--clr-primary-900)"}},[_vm._v(_vm._s(_vm.externalValue))]):_vm._e()]):_vm._e(),(_vm.type == 'html')?_c('html-input-component',{class:("xbec__input__field " + (_vm.borderless && 'c-borderless')),style:(("" + ([null, '', '&zwnj;'].includes(
                _vm.independentMode ? _vm.independentModeValue : _vm.internalValue
            ) &&
            _vm.labeledPlaceholder &&
            _vm.multiSelect
                ? 'display:none'
                : ''))),attrs:{"inline":_vm.inline,"textarea":_vm.textarea,"readonly":_vm.readonly,"background":_vm.background,"additionalClass":_vm.additionalClass},nativeOn:{"blur":function($event){return _vm.test($event)}},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}}):_vm._e(),(_vm.type == 'numeric')?_c('numeric-input-component',{class:("xbec__input__field " + (_vm.borderless && 'c-borderless')),style:(("" + ([null, '', '&zwnj;'].includes(
                _vm.independentMode ? _vm.independentModeValue : _vm.internalValue
            )
                ? 'display:none'
                : ''))),attrs:{"decimalPoints":_vm.decimalPoints,"prepend":_vm.prepend,"append":_vm.append,"readonly":_vm.readonly,"min":_vm.min,"max":_vm.max,"background":_vm.background,"additionalClass":_vm.additionalClass},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }