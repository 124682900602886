import Vue from "vue";

var l = {
    methods: {
        log: function () {
            console.log(...arguments);
        },
        vardump: function (variable) {
            console.log(
                `${typeof variable}(${variable.length ?? Object.keys(variable).length ?? ""}) -> ${variable == "[object Object]" ? JSON.stringify(variable) : variable}`
            );
        },
        notify: function (newvalue, oldvalue, property) {
            console.log(`[WATCHER] old: ${oldvalue} -> new: ${newvalue} | property: ${property}`);
        }
    }
}

var logger = Vue.extend({
    mixins: [l]
});

export default logger;