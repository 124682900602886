<template>
    <div class="modal-naslov">
        <b-icon v-if="znak !== 'bez znaka'" class="modal-znak" :icon="znak" scale="1.6" :variant="boja"></b-icon>
        <span :style="stil" class="modal-tekst">{{naslov}}</span>
    </div>
</template>

<script>
export default {
    props: {
        naslov: {
            type: String,
            default: 'Osnovni modal',
        },
        znak: {
            type: String,
            default: 'bez znaka',
        },
        boja: {
            type: String,
            default: 'secondary',
        },
        stil: {
            type: String,
            default:'color: #495057',
        }
    }
};
</script>

<style scoped>
.modal-naslov {
    height: 3.5rem;
    padding: 1rem 1.2rem 3rem 1.2rem;
}
.modal-znak {
    margin-bottom: .13rem;
    margin-left: .35rem;
}
.modal-tekst {
    font-weight: bold;
    font-size: 1.3rem;
    margin-left: 1rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
</style>