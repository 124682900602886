<template>
    <div class="polje-unosa-datum">
        <b-form-datepicker
            :id="idDatum"
            class="unos"
            locale="sr-RS"
            v-bind="labels || {}"
            v-model="datum"
            :value-as-date="true"
            today-button
            label-today-button="Danas"
            reset-button
            label-reset-button="Poništi"
            close-button
            label-close-button="Zatvori"
            :start-weekday="1"
            :show-decade-nav="false"
            :hide-header="false"
            :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            }"
            :placeholder="naslov"
            :size="velicina"
        ></b-form-datepicker>
    </div>
</template>

<script>
export default {
    name: "PoljeUnosaDatum",
    props: {
        naslov: {
            type: String,
            default: "Naslov",
        },
        velicina: {
            type: String,
            default: "md",
            validator: function (vrednost) {
                return ["sm", "md", "lg"].includes(vrednost);
            },
        },
    },
    data() {
        return {
            datum: null,
            labels: {
                labelPrevDecade: "Prošla decenija",
                labelPrevYear: "Prošla godina",
                labelPrevMonth: "Prošli mesec",
                labelCurrentMonth: "Trenutni mesec",
                labelNextMonth: "Sledeći mesec",
                labelNextYear: "Sledeća godina",
                labelNextDecade: "Sledeća decenija",
                labelToday: "Danas",
                labelSelected: "Izabrani dan",
                labelNoDateSelected: "",
                labelCalendar: "Kalendar",
                labelNav: "Upravljanje kalendarom",
                labelHelp: "Koristite miša da upravljate kalendarom",
            },
        };
    },
    computed: {
        idDatum: function () {
            return "polje-datum-" + this.naslov.replace(/\s/g, "");
        },
    },
    watch: {
        datum: function () {
            this.$emit(
                "promena-unosa",
                this.datum !== null
                    ? Math.round(this.datum.getTime() / 1000)
                    : 0
            );
        },
    },
    methods: {
        spoljniUnos: function ($$vrednost) {
            this.datum = new Date($$vrednost * 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
.polje-unosa-datum {
    margin: 0 !important;
}
</style>