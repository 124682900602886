<template>
    <div class="polje-unosa-tekst">
        <b-input-group :size="velicina">
            <template v-if="prepend != null" #prepend>
                <b-input-group-text class="prepend">{{
                    prepend
                }}</b-input-group-text>
            </template>
            <b-form-input
                class="unos"
                :readonly="!promenljiv"
                :class="desno ? 'text-right' : ''"
                autocomplete="off"
                :type="inputType != '' ? inputType : 'search'"
                :placeholder="placeholder"
                v-model="tekst"
                :id="'unos-tekst-' + naslov.replace(/\s/g, '')"
                @keydown="inputCheck($event)"
            />
            <b-input-group-append v-if="dopis != ''">
                <b-input-group-text>
                    {{ dopis }}
                </b-input-group-text>
            </b-input-group-append>
            <b-input-group-append v-if="dugme != ''">
                <b-button :class="dugmeVrsta" @click="pritisakDugmeta()">
                    {{ dugme }}
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <ponuda-pretrage
            v-if="pretraga.length > 0"
            v-show="prikazPretrage"
            :pretraga="pretraga"
            :unos="tekst"
            :filter="filter"
            @izbor="izbor"
        />
    </div>
</template>

<script>
import PonudaPretrage from "./PoljeUnosaTekst/PonudaPretrage";

export default {
    name: "PoljeUnosaTekst",
    components: {
        PonudaPretrage,
    },
    props: {
        naslov: {
            type: String,
            default: "Naslov unosa",
        },
        pretraga: {
            type: Array,
            default: function () {
                return [];
            },
        },
        dopis: {
            type: String,
            default: "",
        },
        dugme: {
            type: String,
            default: "",
        },
        dugmeVrsta: {
            type: String,
            default: "obradni",
        },
        filter: {
            type: Boolean,
        },
        desno: {
            type: Boolean,
        },
        promenljiv: {
            type: Boolean,
        },
        velicina: {
            type: String,
            default: "md",
            validator: function (vrednost) {
                return ["sm", "md", "lg"].includes(vrednost);
            },
        },
        prepend: {
            type: String,
        },
        inputType: {
            type: String,
            default: "",
        },
    },
    computed: {
        placeholder: function () {
            if (this.prepend !== null) {
                return "";
            }
            return this.naslov;
        },
    },
    data() {
        return {
            tekst: "",
            prikazPretrage: false,
        };
    },
    watch: {
        tekst: function () {
            this.$emit("promena-unosa", this.tekst);
        },
    },
    methods: {
        inputCheck: function (e) {
            if (e.keyCode == 13) {
                this.$emit("enter");
            }
        },
        spoljniUnos: function ($$vrednost) {
            this.tekst = $$vrednost;
            // this.prikazPretrage = false;
        },
        izbor: function ($$vrednost) {
            this.tekst = $$vrednost;
            this.prikazPretrage = false;
        },
        prikaziPretragu: function () {
            this.prikazPretrage = true;
        },
        ukloniPretragu: function (e) {
            if (!this.$el.contains(e.target)) {
                this.prikazPretrage = false;
            }
        },
        pritisakDugmeta: function () {
            this.$emit("pritisak-dugmeta");
        },
    },
    mounted() {
        let el = document.getElementById(
            "unos-tekst-" + this.naslov.replace(/\s/g, "")
        );
        el.addEventListener("focusin", this.prikaziPretragu);
        document.addEventListener("click", this.ukloniPretragu);
        if (this.prepend !== null) {
            el.classList.add("no-left-border");
        }
    },
};
</script>

<style scoped>
.polje-unosa-tekst {
    margin: 0 !important;
}
</style>