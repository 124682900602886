var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal-component',{ref:"supportModal",attrs:{"title":"Podrška","actions":[
            {
                caption: 'Zatvori',
                eventName: 'close',
            },
            {
                caption: 'Pošalji',
                eventName: 'send',
            } ]},on:{"close":function($event){return _vm.hideModal()},"send":function($event){return _vm.sendSupportTicket()}}},[[_c('select-component',{attrs:{"label":"Razlog kontaktiranja","options":_vm.supportReasonOptions,"noClear":""},model:{value:(_vm.supportTicketData.reason),callback:function ($$v) {_vm.$set(_vm.supportTicketData, "reason", $$v)},expression:"supportTicketData.reason"}}),_c('input-component',{attrs:{"type":"html","label":"Poruka","textarea":""},model:{value:(_vm.supportTicketData.message),callback:function ($$v) {_vm.$set(_vm.supportTicketData, "message", $$v)},expression:"supportTicketData.message"}}),_c('div',{staticClass:"\n                    xbec__panel\n                    c-support-contact\n                    x-bg-ultra-light x-shadow-light\n                "},[_c('p',{staticClass:"xbec__panel__title x-fg-light"},[_vm._v(" Kontakt podaci podrške ")]),_c('ul',{staticClass:"xbec__list"},[_c('li',[_vm._v("podrska@amigo.cloud")])])])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }