import { render, staticRenderFns } from "./PonudaIzbora.vue?vue&type=template&id=c5412db8&scoped=true&"
import script from "./PonudaIzbora.vue?vue&type=script&lang=js&"
export * from "./PonudaIzbora.vue?vue&type=script&lang=js&"
import style0 from "./PonudaIzbora.vue?vue&type=style&index=0&id=c5412db8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5412db8",
  null
  
)

export default component.exports