<template>
    <div
        :class="`${classID} xbec__input c-numeric-input ${
            background && `${background}-background`
        }`"
        :contenteditable="!readonly"
        @keydown="hendleKeyDown($event)"
        @input="handleUpdate($event)"
        @focus="handleFocusEvent($event)"
        @blur="handleBlurEvent()"
        v-html="textValue"
        :data-prepend="prepend"
        :data-append="append"
        :key="forceReactivityKey"
    ></div>
</template>

<script>
export default {
    name: "NumericInputComponent",
    props: {
        externalValue: {},
        decimalPoints: {
            type: Number,
            default: 2,
        },
        prepend: {
            type: String,
            default: null,
        },
        append: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            default: null,
        },
        max: {
            type: Number,
            default: null,
        },
        background: {
            type: String,
            default: null,
        },
    },
    model: {
        prop: "externalValue",
        event: "update",
    },
    data() {
        return {
            id: this.generateRandomHex(16),
            inFocus: false,
            textValue: "",
            forceReactivityKey: this.generateRandomHex(24),
            allowedKeys: [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                ",",
                ".",
                "Backspace",
                "Delete",
                "Tab",
                "ArrowUp",
                "ArrowRight",
                "ArrowDown",
                "ArrowLeft",
            ],
        };
    },
    computed: {
        classID: function () {
            return `js-input-${this.id}`;
        },
        internalValue: {
            get: function () {
                return this.externalValue;
            },
            set: function (newValue) {
                this.$emit("update", newValue);
            },
        },
    },
    watch: {
        internalValue: function (newValue) {
            if (!this.inFocus) {
                this.textValue = this.uNovac(
                    newValue,
                    false,
                    this.decimalPoints
                );
            }
        },
        inFocus: function (newValue) {
            window.console.log("infocus", this.inFocus);
            if (!newValue) {
                this.textValue = new String(
                    this.uNovac(this.internalValue, false, this.decimalPoints)
                );
                this.forceReactivityKey = this.generateRandomHex(24);
            }
        },
        min: function () {
            if (this.min != null && this.min > this.internalValue) {
                this.internalValue = this.min;
            }
        },
        max: function () {
            if (this.max != null && this.max < this.internalValue) {
                this.internalValue = this.max;
            }
        },
    },
    methods: {
        hendleKeyDown: function (event) {
            if (
                !this.allowedKeys.includes(event.key) &&
                !(event.metaKey || event.ctrlKey)
            ) {
                event.preventDefault();
            }
        },
        handleFocusEvent: function (event) {
            this.inFocus = true;
            let selectionRange = document.createRange();
            selectionRange.selectNodeContents(event.target);
            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(selectionRange);
        },
        handleBlurEvent: function () {
            this.inFocus = false;
            this.textValue = this.uNovac(
                this.internalValue,
                false,
                this.decimalPoints
            );
        },
        handleUpdate: function (event) {
            let newValue = this.parseToNumeric(event.target.innerHTML);
            if (this.min != null && this.min > newValue) {
                newValue = this.min;
            }
            if (this.max != null && this.max < newValue) {
                newValue = this.max;
            }
            this.internalValue = newValue;
        },
        parseToNumeric: function (stringValue) {
            if (stringValue == null || stringValue == "") return null;
            stringValue = stringValue.trim();
            var result = stringValue.replace(/[^0-9]/g, "");
            const regexExec = `[,\\.]\\d{1,${this.decimalPoints}}$`;
            const regexExecResult = new RegExp(regexExec, "g").exec(
                stringValue
            );
            if (regexExecResult != null) {
                window.console.log("regexExecResult", regexExecResult.index);
                window.console.log("result.length", stringValue.length);
                const regexReplace = `(\\d{${
                    stringValue.length - regexExecResult.index - 1
                }})$`;
                window.console.log(regexReplace);
                result = result.replace(new RegExp(regexReplace), ".$1");
            }
            return parseFloat(result);
        },
        handlePaste: function (event) {
            event.preventDefault();
            // following line works on Google Chrome and Firefox, possibly on IE
            const text = (event.originalEvent || event).clipboardData.getData(
                "text/plain"
            );
            for (let character of text) {
                if (!this.allowedKeys.includes(character)) {
                    alert("Nalepljena vrednost sadrži nenumeričke simbole.");
                    return;
                }
            }
            // execCommand is depracated, but works for now. Ideally, an alternative should be found.
            document.execCommand("insertHTML", false, text);
        },
        addPasteFunctionality: function () {
            const input = document.querySelector(`.${this.classID}`);
            input.removeEventListener("paste", this.handlePaste);
            input.addEventListener("paste", this.handlePaste);
        },
    },
    created: function () {
        this.textValue = this.uNovac(
            this.externalValue,
            false,
            this.decimalPoints
        );
    },
    mounted: function () {
        this.addPasteFunctionality();
    },
};
</script>

<style lang="scss" scoped>
.xbec__input {
    min-height: 32px;
    width: 100%;
    padding: 4px 8px;

    &.c-numeric-input {
        text-align: right;

        &::before {
            content: attr(data-prepend);
        }

        &::after {
            content: attr(data-append);
        }
    }

    &.white-background {
        background-color: var(--clr-white);
    }

    &.light-background {
        background-color: var(--clr-gray-100);
    }
}
</style>